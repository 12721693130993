@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap);
:root {
  --primary-color: rgb(159, 54, 230);
  --text-color: #111;
  --scrollbar-track: #eee;
  --scrollbar-thumb: #666;
  --dark-shadow: rgba(0, 0, 0, 0.5);
  --btn-color: rgba(159, 54, 230, 0.7);
  --font-family: "Montserrat", sans-serif;
  --light-background: #fff;
  --skill-bg-color: rgb(219, 210, 210);
  --skill-fg-color: #111;
  --nav-hover-clr: rgb(211, 211, 211);
  --shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* Scrollbar */

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #fff;
  background: var(--light-background);
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(159, 54, 230);
  background-color: var(--primary-color);
  border-radius: 20px;
}

html {
  width: 100vw;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #111;
  color: var(--text-color);
}

#root {
  overflow-x: hidden !important;
}

/* Bottom Navbar */
.bottom__nav {
  display: none;
}

/* TopNavbar */

.top__navbar {
  width: 100vw;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0px 5px -2px rgb(159, 54, 230);
  box-shadow: 0 0px 5px -2px var(--primary-color);
}

@-webkit-keyframes navbar-border-animation {
  from {
    border-color: white;
    -webkit-filter: hue-rotate(0);
            filter: hue-rotate(0);
  }
  to {
    border-color: blue;
    -webkit-filter: hue-rotate(360);
            filter: hue-rotate(360);
  }
}

@keyframes navbar-border-animation {
  from {
    border-color: white;
    -webkit-filter: hue-rotate(0);
            filter: hue-rotate(0);
  }
  to {
    border-color: blue;
    -webkit-filter: hue-rotate(360);
            filter: hue-rotate(360);
  }
}
.top__navbar .title a {
  font-size: 1.7rem;
  font-weight: 700;
}

.nav_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 100%;
}
.nav_list li {
  list-style: none;
  margin: 0 5px;
  padding: 0.4rem 1rem;
  transition: 300ms ease-in-out;
}

.nav_list li a {
  text-decoration: none;
  color: #111;
  color: var(--text-color);
  font-weight: 600;
  font-size: 1.1rem;
}

.nav_list li:hover {
  border-radius: 5px;
  background-color: rgb(211, 211, 211);
  background-color: var(--nav-hover-clr);
}

/* Contact Page */
.contact {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 100px 10px;
}

.contact .profile img {
  width: 100px;
  border-radius: 100px;
  margin: 1rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 4px var(--dark-shadow);
}

.social__icons {
  width: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  padding-top: 50px;
}

.social__icons h3 {
  margin: 0 auto;
  margin-bottom: 10px;
}

.social__icons .icons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, auto));
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  justify-items: center;
}

.icons a .icon {
  width: 2.5rem;
}
/* Buy me a coffee */

.buy-me-a-coffee {
  margin: 1rem;
}

.buy-me-a-coffee a img {
  width: 150px;
  border-radius: 10px;
}

/* Contact Form */

.contact__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
}
input.field,
textarea.field {
  font-size: 1.1rem;
  width: 100%;
  padding: 0.8rem;
  margin: 0.3rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 3px var(--dark-shadow);
}

input.field:focus,
textarea.field:focus {
  box-shadow: inset 0 0 5px rgb(159, 54, 230);
  box-shadow: inset 0 0 5px var(--primary-color);
}

textarea.field {
  width: 100%;
  font-size: 1.1rem;
  min-height: 120px;
  resize: none;
  font-family: sans-serif;
}

textarea.field::-webkit-scrollbar {
  width: 10px;

  border-radius: 10px;

  /* display: none; */
}

textarea.field::-webkit-scrollbar-track {
  background-color: #eee;
  background-color: var(--scrollbar-track);
  border-radius: 20px;
}

textarea.field::-webkit-scrollbar-thumb {
  background-color: #666;
  background-color: var(--scrollbar-thumb);
  border-radius: 20px;
}

.sendBtn {
  width: 100%;
  max-width: 360px;
  margin: 0.8rem 0;
  padding: 0.8rem;
  border: none;
  border-radius: 0.5rem;
  background-color: rgba(159, 54, 230, 0.7);
  background-color: var(--btn-color);
  font-size: 1.05rem;
  color: #111;
  color: var(--text-color);
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-family: var(--font-family);
  font-weight: 600;
}

.sendBtn:active {
  opacity: 0.5;
}

/* Skills Page CSS */

.title_of_page {
  padding: 20px 0 0 0;
  text-align: center;
}
.page_container {
  padding: 20px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, auto));
  justify-content: center;
  width: 90%;
  grid-gap: 2rem;
  gap: 2rem;
  padding-bottom: 100px;
  z-index: 10;
}

.skill {
  background-color: #fff;
  background-color: var(--light-background);
  max-width: 360px;
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow);
  border-radius: 8px;
}

.skill .image img {
  max-width: 60px;
  border-radius: 12px;
}

.skill_info {
  display: flex;
  width: 100%;
  height: inherit;
  flex-direction: column;
  padding-left: 12px;
  justify-content: space-evenly;
  margin: auto 0;
  text-transform: capitalize;
}

.name_and_level {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  /* margin-top: -10px; */
  font-size: 1.2rem;
}

.level {
  font-weight: bold;
}

.skill_bars {
  position: relative;
}
.skill__bar {
  position: absolute;
  text-align: center;
  height: 10px;
  background-color: #111;
  background-color: var(--skill-fg-color);
  border-radius: 10px;
  z-index: 1;
  -webkit-animation: bar 2s linear;
          animation: bar 2s linear;
}

@-webkit-keyframes bar {
  from {
    width: 0;
  }
}

@keyframes bar {
  from {
    width: 0;
  }
}

.bg-bar {
  width: 100%;
  background-color: rgb(219, 210, 210);
  background-color: var(--skill-bg-color);
  height: 10px;
  z-index: -1;
  border-radius: 10px;
}

.page_container > *:nth-child(odd) {
  -webkit-animation: slide-in-left 1s ease-in-out both;
          animation: slide-in-left 1s ease-in-out both;
}

.page_container > *:nth-child(even) {
  -webkit-animation: slide-in-right 1s ease-in-out both;
          animation: slide-in-right 1s ease-in-out both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(150px);
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(150px);
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-150px);
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-150px);
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/* Loading Animation Style */
.loading {
  width: 100%;
  height: 60vh;
  display: grid;
  place-items: center;
}

/* Projects Page CSS */

.project,
.blog {
  position: relative;
  width: 360px;
  border-radius: 10px;
  max-width: 360px;
  height: auto;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  box-shadow: var(--shadow);
  padding-bottom: 15%;
}

.project .cover img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.project .info {
  width: 100%;
  padding: 10px;
  text-transform: capitalize;
  -webkit-user-select: all;
          user-select: all;
}

.project .info .name {
  padding-bottom: 5px;
}
.project .info .desc {
  font-size: 12px;
  padding-bottom: 5px;
}
.project .info .tools {
  /* font-family: 'Courier New', Courier, monospace; */
  width: 100%;
  text-align: center;
  margin-top: 10px;
  -webkit-user-select: none;
          user-select: none;
}

.project .info .tools img {
  margin: 0 5px;
}
.project .buttons {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.project .buttons > .link {
  position: relative;
  cursor: pointer;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  border-top: 2px solid #adadad;
  opacity: 0.8;
  transition: all 100ms ease-in-out;
}

.project .buttons .link:hover {
  opacity: 1;
  background-color: whitesmoke;
  border-radius: 0 0 10px 10px;
}
.project .buttons > .link > svg {
  width: 40px;
  font-size: 1.5rem;
}

.project .buttons .link:nth-child(2),
.project .buttons .link:nth-child(3) {
  border-left: 2px solid #adadad;
}

/* Blog CSS */

.blog {
  position: relative;
  padding-bottom: 10%;
  word-break: break-word;
}

.sort_by {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 5%;
  flex-direction: column;
  justify-content: space-between;
}

.sort_by > select {
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  background-color: transparent;
  margin-top: 10px;
}

.sort_by > select > option {
  font-size: 16px;
}

.blog > img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid gray;
}

.blog .blogInfo {
  width: 100%;
  padding: 10px;
}

.blog_user {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.blog_user > .user {
  display: flex;
  align-items: center;
}
.user > .blog_details {
  font-size: 10px;
  margin-left: 10px;
}

.blog_info > p {
  padding: 5px 0;
  font-size: 12px;
}

.hot_post {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: orangered;
  transition: all 100ms ease-in-out;
}
.hot_post > p {
  font-size: 12px;
  color: white;
  font-weight: 700;
  margin-left: 6px;
}

.hot_post:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.tags {
  display: flex;
  padding-top: 5px;
  align-items: center;
}

.tags > .tag {
  background-color: rgb(211, 211, 211);
  background-color: var(--nav-hover-clr);
  font-size: 12px;
  padding: 5px;
  margin-right: 7px;
  border-radius: 5px;
  cursor: pointer;
}
.blog_bottom {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 10px;
  background-color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.blog_bottom .blog_vote {
  display: flex;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
}

.blog_vote:hover > p {
  color: #39e58c;
}

.blog_vote:hover > svg {
  fill: #39e58c;
  background-color: whitesmoke;
}

.blog_vote > svg {
  /* transform: rotate(-45deg); */
  font-size: 2rem;
  padding: 5px;
  border-radius: 10px;
  margin-right: 5px;
}

.blog_url {
  display: flex;
  justify-content: center;
}

.blog_url > svg {
  font-size: 2rem;
  padding: 5px;
  background-color: whitesmoke;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

@media screen and (max-width: 680px) {
  body {
    overflow-x: hidden !important;
  }
  .nav_list li a {
    padding: 0.2rem 0.6rem;
    font-size: 2rem;
  }
  /* Bottom Nav Bar for Mobile */
  .bottom__nav {
    position: fixed;
    bottom: 0;
    display: block;
    width: 100vw;
    border-radius: 1rem 1rem 0 0;
    box-shadow: 0 0 3px rgb(159, 54, 230);
    box-shadow: 0 0 3px var(--primary-color);
    background-color: #fff;
    background-color: var(--light-background);
    z-index: 100;
  }
  .bottom__nav_icon {
    display: flex;
    padding: 1rem;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.3s;
  }
  .bottom__nav_icon a {
    transition: all 0.3s ease-in-out;
  }
  .bottom__nav_icon .icon {
    margin: 0.2rem;
    font-size: 2rem;
    transition: all 0.1s ease-in-out;
  }

  /* .bottom__nav_icon */
  .bottom__nav_icon a:active {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  .top__navbar .title {
    width: 100%;
    text-align: center;
  }

  .top__nav_list {
    display: none;
  }

  /* Icons Size on Contact */
  .icons a .icon {
    width: 2rem;
  }
  .social__icons {
    padding: 0;
    margin-top: 2rem;
  }
  .blog {
    padding-bottom: 0;
  }
  .blog_bottom {
    padding-top: 10px;
    position: relative;
    margin: 0;
  }

  .sort_by {
    flex-direction: row;
  }
  .sort_by > select {
    margin-top: 0;
  }
}

/* For Mobile Devices */
@media screen and (max-width: 420px) {
  .page_container {
    margin: 0 auto;
    padding: 0;
    padding-top: 20px;
    grid-gap: 1.3rem;
    gap: 1.3rem;
    padding-bottom: 120px;
    grid-template-columns: repeat(auto-fit, minmax(100%, auto));
  }

  .skill {
    width: 100%;
  }
  .name_and_level {
    font-size: 1rem;
  }
  .project,
  .blog {
    width: 100%;
  }
  .tags > .tag {
    font-size: 10px;
  }
}


